import axios from 'axios';
import '../plugins/element.js';
import constants from "../utils/constants";
import md5 from 'md5-js';
import Cookies from 'js-cookie'
import send from '../utils/sendStatistics';
import rootVueObj from '../main.js';
import utils from "../utils/utils";
let CancelToken = axios.CancelToken;
axios.defaults.withCredentials = true;
let isDisPlayMessage = false;
let cancelSaveSend = null;
export function setToken(token) {
    axios.defaults.headers.common[constants.TOKEN] = token;
    setUserUtm();
}
export async function setBaseUrl(type) {
    if (localStorage.getItem(constants.LOCAL_TOKEN)) {
        setToken(localStorage.getItem(constants.LOCAL_TOKEN))
    }
    if (type == 1) {
        axios.defaults.baseURL = process.env.VUE_APP_API_SERVER;
    } else if (type == 2) {
        axios.defaults.baseURL = process.env.VUE_APP_BLUEPULSE_URL;
    } else if (type == 3) {
        axios.defaults.baseURL = process.env.VUE_APP_HY_API_SERVER;
    }
}
export function vueSetting() {
    let token = '';
    let referrer = document.referrer;
    if (localStorage.getItem(constants.LOCAL_TOKEN)) {
        token = localStorage.getItem(constants.LOCAL_TOKEN)
    }
    setToken(token)
    setBaseUrl(1)
    // axios.defaults.headers.common[constants.TOKEN] = '237C6E94C8057314720F0610AB21B9502E46EACCFAA21EB0';
    if (utils.isPC()) {
        axios.defaults.headers.common['platform'] = constants.PLATFORM_PC;
    } else {
        axios.defaults.headers.common['platform'] = constants.PLATFORM_MOBILE;
    }
    axios.interceptors.response.use(response => {
        let requestUrl = response.config.url;
        if (response.data.code == -1001) {
            if (requestUrl.indexOf("/api/v1/user/de") !== -1 ||
                requestUrl.indexOf("/api/v1/user/hy-de") !== -1 ||
                requestUrl.indexOf("/api/v1/oss-file/oss-params") !== -1 ||
                requestUrl.indexOf("/api/v1/user/login") !== -1 ||
                requestUrl.indexOf("/api/v1/web-record/create") !== -1 ||
                requestUrl.indexOf("/api/v1/oss-file/verify") !== -1
            ) {
                return response
            } else {
                if (!isDisPlayMessage) {
                    isDisPlayMessage = true;
                    rootVueObj.$message({
                        message: response.data.message,
                        customClass:'custom-message',
                        type: 'error'
                    });
                    setTimeout(() => {
                        isDisPlayMessage = false;
                    }, 3000)
                }
                rootVueObj.$router.push({name: 'index'});
            }
        } else {
            return response
        }
    });
    //获取utm
    getUtm();
    // 百度推广
    if (utils.parseQueryString('bd_vid')) {
        setUrlParameter(utils.parseQueryString('bd_vid'), 'bd_vid');
        let millisecond = new Date().getTime();
        let expiresTime = new Date(millisecond + 24 * 60 * 60 * 1000);
        utils.setCookies(constants.BAIDU_BD_VID, window.url_parameter, expiresTime)
        setWebSourceToCookie(constants.WEB_SOURCE, constants.WEB_CHANNEL_BAIDU);
        setWebChannelToRequestHeader(constants.WEB_CHANNEL_BAIDU);
    }
    // 知乎
    if (utils.parseQueryString('source') == 'zhihu') {
        let url = window.location.href;
        let index = url.indexOf('cb=');
        if (index > -1) {
            let callback = url.substring(index + 3, url.length);
            window.url_second_parameter_key = 'cb';
            window.url_second_parameter_value = callback;
            let millisecond = new Date().getTime();
            let expiresTime = new Date(millisecond + 24 * 60 * 60 * 1000);
            utils.setCookies(constants.zhihu_cb, callback, expiresTime)
        }
        setUrlParameter(utils.parseQueryString('source'), 'source');
        setWebSourceToCookie(constants.WEB_SOURCE, constants.WEB_CHANNEL_ZHIHU);
        setWebChannelToRequestHeader(constants.WEB_CHANNEL_ZHIHU);
    }
    // 百度置顶推广
    else if (utils.parseQueryString('source') == constants.WEB_CHANNEL_TOP_BAIDU){
        setUrlParameter(utils.parseQueryString('source'), 'source');
        setWebSourceToCookie(constants.WEB_SOURCE, constants.WEB_CHANNEL_TOP_BAIDU);
        setWebChannelToRequestHeader(constants.WEB_CHANNEL_TOP_BAIDU);
    }
    // 绘影字幕
    else if (utils.parseQueryString('source') == constants.WEB_CHANNEL_HY){
        setUrlParameter(utils.parseQueryString('source'), 'source');
        setWebSourceToCookie(constants.WEB_SOURCE, constants.WEB_CHANNEL_HY);
        setWebChannelToRequestHeader(constants.WEB_CHANNEL_HY);
    }
    // bilibili
    else if (utils.parseQueryString('source') == constants.WEB_CHANNEL_BLIBILI){
        setUrlParameter(utils.parseQueryString('source'), 'source');
        setWebSourceToCookie(constants.WEB_SOURCE, constants.WEB_CHANNEL_BLIBILI);
        setWebChannelToRequestHeader(constants.WEB_CHANNEL_BLIBILI);
    } else if (utils.parseQueryString('source')) {
        //其他source
        setWebSourceToCookie(constants.WEB_SOURCE, utils.parseQueryString('source'));
    }
    //360推广
    if (utils.parseQueryString('utm_source') === '360') {
        //获取qhclickid
        let qhclickid = utils.parseQueryString('qhclickid');
        if (qhclickid) {
            let millisecond = new Date().getTime();
            let expiresTime = new Date(millisecond + 24 * 60 * 60 * 1000);
            utils.setCookies(constants.VIZARD_360_QHCLICKID, qhclickid, expiresTime)
        }
        setUrlParameter(utils.parseQueryString('utm_source'), 'utm_source');
        setWebSourceToCookie(constants.WEB_SOURCE, constants.WEB_CHANNEL_360);
        setWebChannelToRequestHeader(constants.WEB_CHANNEL_360);
    }
    // 自然搜索
    if (referrer) {
        // 百度自然搜索
        if (referrer.indexOf(constants.WEB_BAIDU_ORIGINAL_REFERRER) > -1) {
            setWebChannelToRequestHeader(constants.WEB_BAIDU_ORIGINAL);
            setWebSourceToCookie(constants.WEB_SOURCE, constants.WEB_BAIDU_ORIGINAL)
        }
        // 360自然搜索
        if (referrer.indexOf(constants.WEB_360_ORIGINAL_REFERRER) > -1) {
            setWebChannelToRequestHeader(constants.WEB_360_ORIGINAL);
            setWebSourceToCookie(constants.WEB_SOURCE, constants.WEB_360_ORIGINAL)
        }
        // sogou自然搜索
        if (referrer.indexOf(constants.WEB_SOGOU_ORIGINAL_REFERRER) > -1) {
            setWebChannelToRequestHeader(constants.WEB_SOGOU_ORIGINAL);
            setWebSourceToCookie(constants.WEB_SOURCE, constants.WEB_SOGOU_ORIGINAL)
        }
    }
    //旧网址携带的source
    if (utils.parseQueryString('web_source')){
        let source = utils.parseQueryString('web_source');
        //推广渠道是百度取一下bd_vid
        if (source === constants.WEB_CHANNEL_BAIDU) {
            if (utils.parseQueryString('web_bd_vid')) {
                let millisecond = new Date().getTime();
                let expiresTime = new Date(millisecond + 24 * 60 * 60 * 1000);
                utils.setCookies(constants.BAIDU_BD_VID, utils.parseQueryString('web_bd_vid'), expiresTime)
            }
        } else if (source === constants.WEB_CHANNEL_360) {
            //360推广取QHCLICKID
            if (utils.parseQueryString('web_360_qhclickid')) {
                let millisecond = new Date().getTime();
                let expiresTime = new Date(millisecond + 24 * 60 * 60 * 1000);
                utils.setCookies(constants.VIZARD_360_QHCLICKID, utils.parseQueryString('web_360_qhclickid'), expiresTime)
            }
        }

        setUrlParameter(utils.parseQueryString('web_source'), 'web_source');
        setWebSourceToCookie(constants.WEB_SOURCE, source);
        setWebChannelToRequestHeader(source);
    }
    if (Cookies.get(constants.WEB_SOURCE)) {
        setWebChannelToRequestHeader(Cookies.get(constants.WEB_SOURCE));
    } else {
        setWebChannelToRequestHeader('none');
    }
}
function getUtm(){
    let utmKey = [];
    let url = document.location.toString();
    let arrObj = url.split("?");
    let params = Object.create(null)
    if (arrObj.length > 1) {
        arrObj = arrObj[1].split("&");
        arrObj.forEach(item => {
            item = item.split("=");
            params[item[0]] = item[1]
        })
    }
    for (let key in params) {
        if (key.indexOf('utm') !== -1) {
            utmKey.push(key)
        }
    }
    if (utmKey.length !== 0) {
        let saveUtm = ''
        utmKey.forEach((item) => {
            saveUtm = '&' + item + '=' + params[item] + saveUtm;
        })
        saveUtm = saveUtm.substring(1, saveUtm.length);
        let millisecond = new Date().getTime();
        let expiresTime = new Date(millisecond + 24 * 60 * 60 * 1000);
        utils.setCookies(constants.VIZARD_WEB_UTM, saveUtm, expiresTime)
    }
}
// 设置url跳转参数
export function setUrlParameter(parameter, parameterKey){
    window.url_parameter = parameter;
    window.url_parameter_key = parameterKey;
}
//存储网站来源信息到cookies
export function setWebSourceToCookie(name, value) {
    //清除旧来源cookie
    Cookies.remove(constants.WEB_SOURCE);
    //添加新来源cookie
    let millisecond = new Date().getTime();
    let expiresTime = new Date(millisecond + 24 * 60 * 60 * 1000);
    utils.setCookies(name, value, expiresTime);
}
export function setWebChannelToRequestHeader(value) {
    axios.defaults.headers.common[constants.WEB_CHANNEL] = value;
}
// 设置用户utm
export function setUserUtm(){
    if (Cookies.get(constants.VIZARD_WEB_UTM)) {
        let params = [];
        let utmKey = [];
        let arrObj = Cookies.get(constants.VIZARD_WEB_UTM).split("&");
        arrObj.forEach(item => {
            item = item.split("=");
            params[item[0]] = item[1]
        })
        for (let key in params) {
            if (key.indexOf('utm') !== -1) {
                utmKey.push(key)
            }
        }
        for (let i = 0; i < utmKey.length; i++) {
            let obj = {};
            obj['v_' + utmKey[i]] = decodeURIComponent(params[utmKey[i]]);
            send.profileSet(obj);
        }
    }
}
//获取存储的网站来源信息
export function setWebChannelToRequestHeaderByCookie(shareCode){
    let sendShareCode = '';
    if (shareCode) {
        sendShareCode = shareCode
    }
    if (Cookies.get(constants.WEB_SOURCE)) {
        setWebChannelToRequestHeader(Cookies.get(constants.WEB_SOURCE));
        send.profileSet({user_channel:Cookies.get(constants.WEB_SOURCE)})
        send.profileSet({register_time:utils.formatDateTimeRegister(new Date().getTime())})
        send.collect(send.homepage_newRegisterUser, {channel:Cookies.get(constants.WEB_SOURCE), share_code:sendShareCode});
    } else {
        setWebChannelToRequestHeader('none');
        send.profileSet({user_channel:'none'})
        send.profileSet({register_time:utils.formatDateTimeRegister(new Date().getTime())})
        send.collect(send.homepage_newRegisterUser, {channel:'none', share_code:sendShareCode});
    }
}
export async function login(username, password, from) {
    setBaseUrl(1)
    let salt = "qiluomite_XYZ_654321^&";
    let str = password + salt;
    let obj = {};
    if (username.trim() !== "") {
        obj.loginMobile = username
    }
    if (from) {
        obj.from = from
    }
    if (password.trim() !== "") {
        obj.loginPwd = md5(md5(str)+salt)
    }
    return axios
        .post('/api/v1/user/login', obj)
        .then(({ data }) => data);
}
export function weChatLoginWeb(code) {
    setBaseUrl(1);
    let obj = {
        code:code
    };
    return axios
        .post('/api/v1/user/wechat-web-login', obj)
        .then(({ data }) => data);
}
//微信公众号登录
export function weChatLoginPublic(code) {
    setBaseUrl(1);
    let obj = {
        code:code
    };
    return axios
        .post('/api/v1/user/wechat-offiaccount-login', obj)
        .then(({ data }) => data);
}
export function getHyToken(token) {
    setBaseUrl(1);
    let obj = {
        token:token
    };
    return axios
        .post('/api/v1/user/hy-de', obj)
        .then(({ data }) => data);
}
export function getVizardToken(token, product) {
    setBaseUrl(1);
    let obj = {
        token
    }
    if (product) {
        obj.product = Number(product)
    }
    return axios.post('/api/v1/user/de', obj)
        .then(({ data }) => data);
}
export function getUserRecordCount(duration) {
    setBaseUrl(1);
    let url = '/api/v1/user/user-record-count';
    if (duration) {
        url = url + '?duration=' + duration
    }
    return axios.get(url)
        .then(({ data }) => data);
}
export function bindEmail(email, verifyCode) {
    setBaseUrl(1);
    let obj = {
        email,
        verifyCode
    }
    return axios.post('/api/v1/user/email-bind', obj)
        .then(({ data }) => data);
}
export function getShareDetailsByCode(shareCode, sendEvent, sharePwd, displayType, littleData) {
    setBaseUrl(1);
    let obj = new FormData();
    obj.append("shareCode",shareCode);
    if (sendEvent) {
        obj.append("sendEvent",sendEvent);
    } else {
        obj.append("sendEvent",false);
    }
    if (sharePwd) {
        obj.append("sharePwd", sharePwd);
    }
    if (displayType) {
        obj.append("displayType", displayType);
    }
    if (littleData) {
        obj.append("littleData", littleData);
    }
    return axios
        .post('/api/v1/share/detail', obj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(({ data }) => data);
}
export function deleteShareWithMe(id) {
    setBaseUrl(1);
    let obj = new FormData();
    obj.append("id", id);
    return axios
        .post('/api/v1/share/delete-my-share-view-history', obj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(({ data }) => data);
}
export function getShareWithMeList(videoName, pageIndex, pageSize) {
    setBaseUrl(1);
    let obj = new FormData();
    obj.append("pageIndex",pageIndex);
    obj.append("pageSize",pageSize);
    if (videoName !== '') {
        obj.append("videoName",videoName);
    }
    return axios
        .post('/api/v1/share/list-my-share-view-history', obj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(({ data }) => data);
}
export function exportVideo(exportJson, id, videoType) {
    setBaseUrl(1);
    let obj = {
        exportJson,
        id,
        videoType,
    }
    return axios.post('/api/v1/final-video/export', obj)
        .then(({ data }) => data);
}
export function setExportVideoDisplay(finalVideoId) {
    setBaseUrl(1);
    let obj = {
        finalVideoId
    }
    return axios.post('/api/v1/final-video/display', obj)
        .then(({ data }) => data);
}
export function bindPhone(phone, verifyCode) {
    setBaseUrl(1);
    let obj = {
        phone,
        verifyCode
    };
    return axios
        .post('/api/v1/user/phone-bind', obj)
        .then(({ data }) => data);
}
export function newUserBindPhone(phone, verifyCode, unionId) {
    setBaseUrl(1);
    let obj = {
        phone,
        verifyCode,
        thirdPartyType:1,
        unionId
    };
    return axios
        .post('/api/v1/user/third-party-login-with-phone-bind', obj)
        .then(({ data }) => data);
}
export function uploadFileSimple(blob, fileName, fileRemotePath) {
    setBaseUrl(1);
    let obj = new FormData();
    let fileOfBlob = new File([blob], fileName);
    obj.append("file", fileOfBlob);
    obj.append("businessType",1);
    obj.append("fileRemotePath",fileRemotePath);
    return axios
        .post('/api/v1/file/simple-upload', obj,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(({ data }) => data);
}
export function uploadUserJsonContent(recordId, userJsonStr, isEditorText) {
    setBaseUrl(1)
    let obj = new FormData();
    let userJson = {
        userJson:userJsonStr,
        version:constants.USERJSON_VERSION,
        isEditorText
    }
    if (cancelSaveSend) {
        cancelSaveSend();
    }
    obj.append('userJsonStr', JSON.stringify(userJson));
    return axios.post('/api/v1/record/upload-userjson-content/' + recordId, obj, {
        cancelToken: new CancelToken(function executor(c) {
            // executor 函数接收一个 cancel 函数作为参数
            cancelSaveSend = c;
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }).then(({ data }) => data);
}
export function uploadWebRecordUserJsonContent(recordId, userJsonStr, isEditorText) {
    setBaseUrl(1)
    let obj = new FormData();
    let userJson = {
        userJson:userJsonStr,
        version:constants.USERJSON_VERSION,
        isEditorText
    }
    if (cancelSaveSend) {
        cancelSaveSend();
    }
    obj.append('userJsonStr', JSON.stringify(userJson));
    return axios.post('/api/v1/web-record/upload-userjson-content/' + recordId, obj, {
        cancelToken: new CancelToken(function executor(c) {
            // executor 函数接收一个 cancel 函数作为参数
            cancelSaveSend = c;
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }).then(({ data }) => data);
}
export function uploadDumpVideoUserJsonContent(recordId, userJsonStr, isEditorText) {
    setBaseUrl(1)
    let obj = new FormData();
    let userJson = {
        userJson:userJsonStr,
        version:constants.USERJSON_VERSION,
        isEditorText
    }
    if (cancelSaveSend) {
        cancelSaveSend();
    }
    obj.append('userJsonStr', JSON.stringify(userJson));
    return axios.post('/api/v1/dump-video/upload-userjson-content/' + recordId, obj, {
        cancelToken: new CancelToken(function executor(c) {
            // executor 函数接收一个 cancel 函数作为参数
            cancelSaveSend = c;
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }).then(({ data }) => data);
}
export function uploadOrderUserJsonContent(orderId, userJsonStr, isEditorText) {
    setBaseUrl(1)
    let obj = new FormData();
    let userJson = {
        userJson:userJsonStr,
        version:constants.USERJSON_VERSION,
        isEditorText
    }
    if (cancelSaveSend) {
        cancelSaveSend();
    }
    obj.append('userJsonStr', JSON.stringify(userJson));
    return axios.post('/api/v1/order/upload-userjson-content/' + orderId, obj, {
        cancelToken: new CancelToken(function executor(c) {
            // executor 函数接收一个 cancel 函数作为参数
            cancelSaveSend = c;
        }),
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    }).then(({ data }) => data);
}
export function getShare(shareId, shareWay, videoType, msDuration) {
    setBaseUrl(1);
    let obj = new FormData();
    obj.append("shareId", shareId);
    obj.append("videoType", videoType);
    obj.append("shareWay", shareWay);
    obj.append("msDuration", msDuration);
    return axios
        .post('/api/v1/share/gen-share-info', obj,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(({ data }) => data);
}
export async function middlePageInfo(shareCode) {
    setBaseUrl(1)
    let obj = new FormData();
    obj.append('shareCode', shareCode);
    return axios
        .post('/api/v1/share/middle-page-info', obj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(({ data }) => data);
}
export async function getWechatJsAuthParams(url) {
    setBaseUrl(1)
    let obj = {
        url
    }
    return axios
        .post('/api/v1/user/wechat-js-api-auth-params', obj, )
        .then(({ data }) => data);
}
export async function bindWx(code) {
    setBaseUrl(1)
    let obj = {
        code
    }
    return axios
        .post('/api/v1/user/wechat-bind', obj, )
        .then(({ data }) => data);
}
export async function submitClientAsrRecognize(recordId, lang) {
    setBaseUrl(1)
    let obj = {
        recordId:Number(recordId),
        lang:Number(lang)
    }
    return axios
        .post('/api/v1/record/submit-asr-recognize', obj, )
        .then(({ data }) => data);
}
export async function submitWebAsrRecognize(recordId, lang) {
    setBaseUrl(1)
    let obj = {
        webRecordId:Number(recordId),
        lang:Number(lang)
    }
    return axios
        .post('/api/v1/web-record/submit-asr-recognize', obj, )
        .then(({ data }) => data);
}
export async function submitUploadAsrRecognize(id, lang) {
    setBaseUrl(1)
    let obj = {
        orderId:Number(id),
        lang:Number(lang)
    }
    return axios
        .post('/api/v1/order/submit-asr-recognize', obj, )
        .then(({ data }) => data);
}
export async function submitDumpAsrRecognize(id, lang) {
    setBaseUrl(1)
    let obj = {
        id:Number(id),
        lang:Number(lang)
    }
    return axios
        .post('/api/v1/dump-video/submit-asr-recognize', obj, )
        .then(({ data }) => data);
}
export async function editDumpSwitch(videoId, videoType, enable) {
    setBaseUrl(1)
    let obj = {
        videoId:Number(videoId),
        videoType,
        enable
    }
    return axios
        .post('/api/v1/dump-video/edit-switch', obj, )
        .then(({ data }) => data);
}
export async function editShareSwitch(videoId, videoType, dumpPermission, pwdProtection, textContentPermission, sharePwd) {
    setBaseUrl(1)
    let obj = {
        videoId:Number(videoId),
        videoType,
        dumpPermission,
        pwdProtection,
        textContentPermission,
        sharePwd
    }
    return axios
        .post('/api/v1/share/edit-share-permission', obj, )
        .then(({ data }) => data);
}
export async function getShareSwitch(videoId, videoType) {
    setBaseUrl(1)
    let obj = {
        videoId:Number(videoId),
        videoType,
    }
    return axios
        .post('/api/v1/share/share-permission-info', obj, )
        .then(({ data }) => data);
}
export async function logout() {
    setBaseUrl(1)
    return axios
        .get('/api/v1/user/logout')
        .then(({ data }) => data);
}
export async function getUserAsrCont() {
    setBaseUrl(1)
    return axios
        .get('/api/v1/user/user-asr-count')
        .then(({ data }) => data);
}
export async function feedback(content, contactInfo){
    setBaseUrl(1);
    let obj = {
        content,
        contactInfo
    };
    return axios
        .post('api/v1/feedback/add', obj)
        .then(({ data }) => data);
}
export async function getClientDetail(recordId){
    setBaseUrl(1);
    let obj = {
        recordId:Number(recordId)
    };
    return axios
        .post('api/v1/record/detail', obj)
        .then(({ data }) => data);
}
export async function saveClipInfo(videoId, videoType, clipInfoArr){
    setBaseUrl(1);
    let obj = {
        videoId:Number(videoId),
        videoType:Number(videoType),
        clipJsonStr:JSON.stringify(clipInfoArr)
    };
    return axios
        .post('api/v1/web-record/save-clip-info-v2', obj)
        .then(({ data }) => data);
}
export async function getWebRecordWaveform(webRecordId) {
    setBaseUrl(1)
    return axios
        .get('/api/v1/web-record/waveform/' + webRecordId)
        .then(({ data }) => data);
}
export async function getDumpVideoWaveform(webRecordId) {
    setBaseUrl(1)
    return axios
        .get('/api/v1/dump-video/waveform/' + webRecordId)
        .then(({ data }) => data);
}
export async function getUploadVideoWaveform(orderId) {
    setBaseUrl(1)
    return axios
        .get('/api/v1/order/waveform/' + orderId)
        .then(({ data }) => data);
}
export async function getClientRecordWaveform(recordId) {
    setBaseUrl(1)
    return axios
        .get('/api/v1/record/waveform/' + recordId)
        .then(({ data }) => data);
}
export function getFile(url) {
    setBaseUrl(1)
    return axios({
        method: 'get',
        url: url
    }).then(({ data }) => data);
}
export function getBlob(url) {
    setBaseUrl(1)
    return axios({
        method: 'get',
        url: url,
        responseType:"blob"
    }).then(({ data }) => data);
}
export function recordShare(webRecordId) {
    setBaseUrl(1)
    let obj = {
        webRecordId
    }
    return axios.post('/api/v1/web-record/share', obj)
        .then(({ data }) => data);
}
export function downNoCaptionVideo(downloadJson, id, videoType) {
    setBaseUrl(1)
    let obj = {
        downloadJson,
        id,
        videoType
    }
    return axios.post('/api/v1/download-video/download', obj)
        .then(({ data }) => data);
}
export function getHyShareList(videoName, pageIndex, pageSize) {
    setBaseUrl(1)
    let obj = new FormData();
    obj.append('pageIndex', pageIndex)
    obj.append('pageSize', pageSize)
    if (videoName) {
        obj.append('videoName', videoName)
    }
    return axios.post('/api/v1/share/list-my-hy-share', obj, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
        .then(({ data }) => data);
}
export function downWebRecord(id, downloadJson, videoType) {
    setBaseUrl(1)
    let obj = {
        id,
        downloadJson,
        videoType
    }
    return axios.post('/api/v1/download-video/download', obj)
        .then(({ data }) => data);
}
export function getDownLoadResult(id) {
    setBaseUrl(1)
    return axios
        .get('/api/v1/download-video/download-result/' + id, {

        })
        .then(({ data }) => data);
}
export function isWhitelist() {
    setBaseUrl(1)
    return axios
        .get('/api/v1/order/is-whitelist', {
        })
        .then(({ data }) => data);
}
export function getWebRecordDownLoadResult(id) {
    setBaseUrl(1)
    return axios
        .get('/api/v1/download-video/download-result/' + id, {

        })
        .then(({ data }) => data);
}
export function getUserSubmitAsrCount() {
    setBaseUrl(1)
    return axios
        .get('/api/v1/user/user-submit-asr-count', {

        })
        .then(({ data }) => data);
}
export function upload360ConvertData() {
    setBaseUrl(1)
    if (Cookies.get(constants.WEB_SOURCE) === constants.WEB_CHANNEL_360 && Cookies.get(constants.VIZARD_360_QHCLICKID)) {
        let obj = {
            request_time:utils.getUTCTime(),
            data_industry:"ocpc_ps_convert",
            data_detail:{
                qhclickid:Cookies.get(constants.VIZARD_360_QHCLICKID),
                event:"REGISTERED",
                trans_id:new Date().getTime()
            }
        }
        return axios
            .post('/api/v1/ocpc/upload-360-web-convert', obj)
            .then(({ data }) => data);
    }
}
export function uploadBaiduConvertData(newType, convertValue) {
    setBaseUrl(1)
    let obj = {};
    let url = '';
    if (process.env.VUE_APP_IS_REGISTER_SHOWN !== 'production') {
        url = 'https://t.vizard.video';
    } else {
        if (window.location.href.indexOf('vizard.video') !== -1) {
            url = 'https://vizard.video';
        } else {
            url = 'https://vizard.huiyingzimu.com'
        }
    }
    obj.conversionTypes = [{}];
    obj.conversionTypes[0].logidUrl = url + '?bd_vid=' + Cookies.get(constants.BAIDU_BD_VID);
    obj.conversionTypes[0].newType = newType;
    if (convertValue) {
        obj.conversionTypes[0].convertValue = convertValue;
    }
    obj.conversionTypes[0].isConvert = 1;
    obj.conversionTypes[0].convertTime = new Date().getTime();
    return axios
        .post('/api/v1/ocpc/upload-convert-data', obj)
        .then(({ data }) => data);
}

export async function isGetImageCod(mobile) {
    setBaseUrl(1)
    return axios
        .get('/api/v1/user/img-code/get-img-key?mobile=' + mobile)
        .then(({ data }) => data);
}
export async function getImage(imgKey) {
    return axios.defaults.baseURL + "/api/v1/user/img-code?imgKey=" + imgKey;
}
export async function getPhoneCode(mobile, type, captchaKey) {
    setBaseUrl(1)
    return axios
        .post('/api/v1/user/req-sms', {
            mobile,
            type,
            captchaKey
        })
        .then(({ data }) => data);
}
export async function dumpVideoCreate(shareSnapshotId, videoName, transcribeState, dumpJson) {
    setBaseUrl(1)
    let obj = {
        shareSnapshotId,
        videoName,
        transcribeState,
    };
    if (dumpJson) {
        obj.dumpJson = dumpJson;
    }
    return axios
        .post('/api/v1/dump-video/create', obj)
        .then(({ data }) => data);
}
export async function getDumpState(videoId, videoType) {
    setBaseUrl(1)
    let obj = {
        videoId,
        videoType
    };
    return axios
        .post('/api/v1/dump-video/switch', obj)
        .then(({ data }) => data);
}
export async function createM3u8File(webRecordId) {
    setBaseUrl(1)
    let obj = {
        webRecordId
    };
    return axios
        .post('/api/v1/web-record/gen-m3u8', obj)
        .then(({ data }) => data);
}
export async function smsCodeLogin(mobile, smsCode) {
    setBaseUrl(1)
    let obj = {
        mobile,
        smsCode
    };
    return axios
        .post('/api/v1/user/sms-code-login', obj)
        .then(({ data }) => data);
}
export async function rename(recordId, recordName) {
    setBaseUrl(1)
    let obj = new FormData();
    obj.append('recordId', recordId)
    obj.append('recordName', recordName)
    return axios
        .post('/api/v1/record/rename', obj,{
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        }
    })
        .then(({ data }) => data);
}
export async function dumpVideoRename(id, videoName) {
    setBaseUrl(1)
    let obj = {
        id,
        videoName
    };
    return axios
        .post('/api/v1/dump-video/rename', obj)
        .then(({ data }) => data);
}
export async function uploadVideoRename(orderId, fileName) {
    setBaseUrl(1)
    let obj = new FormData();
    obj.append('orderId', orderId)
    obj.append('fileName', fileName)
    return axios
        .post('/api/v1/order/rename', obj)
        .then(({ data }) => data);
}
export async function webRename(recordId, recordName) {
    setBaseUrl(1)
    let obj = {
        webRecordId:recordId,
        recordName:recordName
    };
    return axios
        .post('/api/v1/web-record/rename', obj)
        .then(({ data }) => data);
}
export async function vizardRename(id, videoName) {
    setBaseUrl(1)
    let obj = {
        id:id,
        videoName:videoName
    };
    return axios
        .post('/api/v1/vizard-video/rename', obj)
        .then(({ data }) => data);
}
///成品视频重命名
export async function finalVideoRename(finalVideoId, newName) {
    setBaseUrl(1)
    let obj = {
        finalVideoId,
        newName
    };
    return axios
        .post('/api/v1/final-video/rename', obj)
        .then(({ data }) => data);
}
export async function registered(mobilephone, password, operationcode) {
    setBaseUrl(1)
    let salt = "qiluomite_XYZ_654321^&";
    let str = password + salt;
    return axios
        .post('/api/v1/user/reg',{
            loginMobile: mobilephone,
            loginPwd: md5(md5(str)+salt),
            verifyCode: operationcode
        })
        .then(({ data }) => data);
}
export async function authenticate(phoneNumber, verifyCode, newPassword) {
    setBaseUrl(1)
    let salt = "qiluomite_XYZ_654321^&";
    let str = newPassword + salt;
    return axios
        .post('/api/v1/user/reset-pwd',{
            loginMobile: phoneNumber,
            verifyCode: verifyCode,
            newPwd:md5(md5(str)+salt),
        })
        .then(({ data }) => data);
}
export async function deleteRecord(recordId){
    setBaseUrl(1)
    return axios
        .delete('/api/v1/record/delete/' + recordId)
        .then(({ data }) => data);
}
export async function deleteDumpVideo(id){
    setBaseUrl(1)
    return axios
        .delete('/api/v1/dump-video/delete/' + id)
        .then(({ data }) => data);
}
export async function deleteWebRecord(recordId){
    setBaseUrl(1)
    return axios
        .delete('/api/v1/web-record/delete/' + recordId)
        .then(({ data }) => data);
}
export async function deleteVizardRecord(recordId){
    setBaseUrl(1)
    return axios
        .delete('/api/v1/vizard-video/delete/' + recordId)
        .then(({ data }) => data);
}
export async function deleteOrder(orderId){
    setBaseUrl(1)
    return axios
        .delete('/api/v1/order/delete/' + orderId)
        .then(({ data }) => data);
}
export async function deleteFinalVideo(orderId){
    setBaseUrl(1)
    return axios
        .delete('/api/v1/final-video/delete/' + orderId)
        .then(({ data }) => data);
}
export async function createOrder(orderList){
    setBaseUrl(1)
    return axios
        .post('/api/v1/order/create', orderList)
        .then(({ data }) => data);
}
export async function getOrderDetails(orderId){
    setBaseUrl(1)
    let obj = {
        orderId
    }
    return axios
        .post('/api/v1/order/detail', obj)
        .then(({ data }) => data);
}
export async function getDumpVideoDetails(id){
    setBaseUrl(1)
    return axios
        .get('/api/v1/dump-video/detail/' + id)
        .then(({ data }) => data);
}
export async function en() {
    return axios
        .get('/api/v1/user/en')
        .then(({ data }) => data);
}
export async function getAllRecordList(pageIndex, pageSize, recordName, recordType){
    setBaseUrl(1)
    let obj = {
        pageIndex,
        pageSize,
        recordType
    };
    if (recordName !== '') {
        obj.recordName = recordName
    }
    return axios
        .post('/api/v1/record/get-all-records', obj)
        .then(({ data }) => data);
}
export async function getWebRecordList(pageIndex, pageSize, recordName){
    setBaseUrl(1)
    let obj = {
        pageIndex,
        pageSize,
    };
    if (recordName !== '') {
        obj.recordName = recordName
    }
    return axios
        .post('/api/v1/web-record/list', obj)
        .then(({ data }) => data);
}
export async function getVizardVideoList(pageIndex, pageSize, videoName, listType, expired){
    setBaseUrl(1)
    let obj = {
        pageIndex,
        pageSize,
    };
    if (videoName !== '') {
        obj.videoName = videoName
    }
    if (listType !== '') {
        obj.listType = listType
    }
    if (expired) {
        obj.expired = expired
    }
    return axios
        .post('/api/v1/vizard-video/list', obj)
        .then(({ data }) => data);
}
export async function getDumpVideoList(pageIndex, pageSize, videoName){
    setBaseUrl(1)
    let obj = {
        pageIndex,
        pageSize,
    };
    if (videoName !== '') {
        obj.videoName = videoName
    }
    return axios
        .post('/api/v1/dump-video/list', obj)
        .then(({ data }) => data);
}
export async function getArtcPushInfo(){
    setBaseUrl(1)
    return axios
        .get('/api/v1/record/artc-push-info')
        .then(({ data }) => data);
}
export function getWebRecordDetail(recordId) {
    setBaseUrl(1)
    return axios.get('/api/v1/web-record/detail/' + recordId)
        .then(({ data }) => data);
}
export function getRecordDetail(recordId) {
    setBaseUrl(1)
    let obj = {
        recordId
    }
    return axios.post('/api/v1/web-record/detail', obj)
        .then(({ data }) => data);
}
export function getExportNumber() {
    setBaseUrl(1)
    return axios.get('api/v1/final-video/export-number')
        .then(({ data }) => data);
}
export async function createWebRecord(ossFileId, recordName, msDuration, width, height, cameraStreamOssFileId, cameraStreamMsDuration, cameraStreamWidth, cameraStreamHeight, paramJson, recordMode, mirrorType, templateJson){
    setBaseUrl(1)
    let obj = {
        ossFileId,
        recordName,
        msDuration,
        width,
        height,
        recordMode,
        mirrorType
    };
    if (cameraStreamOssFileId) {
        obj.cameraStreamOssFileId = cameraStreamOssFileId;
        obj.cameraStreamMsDuration = cameraStreamMsDuration;
        obj.cameraStreamWidth = cameraStreamWidth;
        obj.cameraStreamHeight = cameraStreamHeight;
        obj.paramJson = paramJson;
    }
    if (templateJson) {
        obj.templateJsonStr = templateJson;
    }
    return axios
        .post('/api/v1/web-record/create', obj)
        .then(({ data }) => data);
}
export async function getAllOrderList(pageIndex, pageSize, fileName){
    setBaseUrl(1)
    let obj = {
        pageIndex,
        pageSize
    };
    if (fileName !== '') {
        obj.fileName = fileName
    }
    return axios
        .post('/api/v1/order/get-all-orders', obj)
        .then(({ data }) => data);
}
export async function getAllFinalList(pageIndex, pageSize, fileName){
    setBaseUrl(1)
    let obj = {
        pageIndex,
        pageSize
    };
    if (fileName !== '') {
        obj.fileName = fileName
    }
    return axios
        .post('/api/v1/final-video/list', obj)
        .then(({ data }) => data);
}
//获取成品视频列表V2(057).
export async function getExportList(){
    setBaseUrl(1)
    return axios
        .get('/api/v1/final-video/export-list')
        .then(({ data }) => data);
}
export async function getFinalDetails(finalVideoId){
    setBaseUrl(1)
    return axios
        .get('/api/v1/final-video/detail/' + finalVideoId)
        .then(({ data }) => data);
}
export function getStsParams(fileBusinessType, ext) {
    setBaseUrl(1)
    let obj = {
        "fileBusinessType":fileBusinessType,
        "ext":ext
    };
    return axios
        .post('/api/v1/oss-file/oss-params', obj)
        .then(({ data }) => data);
}
export function getLatestVersionInfo(platform, packageName) {
    setBaseUrl(2)
    let obj = new FormData();
    obj.append('platform', platform)
    obj.append('packageName', packageName)
    return axios
        .post('/api/app/latest-version-info', obj, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(({ data }) => data);
}
export function getOssFileVerify(fileBusinessType, bucketName, objectKey) {
    setBaseUrl(1)
    let obj = {
        "fileBusinessType":fileBusinessType,
        "bucketName":bucketName,
        "objectKey":objectKey
    };
    return axios
        .post('/api/v1/oss-file/verify', obj)
        .then(({ data }) => data);
}
export function getFileIdByOssFileId(md5, fileName, length, ext, ossFileId) {
    setBaseUrl(1)
    let obj = {
        "md5":md5,
        "fileName":fileName,
        "length":length,
        "ext":ext,
        "ossFileId":ossFileId
    };
    return axios
        .post('/api/v1/file/direct-upload', obj)
        .then(({ data }) => data);
}
export function getUploadState(fileId) {
    setBaseUrl(1)
    return axios
        .get('/api/v1/file/upload-state/' + fileId)
        .then(({ data }) => data);
}
export function getHokdoConfig() {
    setBaseUrl(1)
    return axios
        .get('/api/v1/config/hokdo')
        .then(({ data }) => data);
}
export function deleteFile(fileId) {
    setBaseUrl(1)
    return axios
        .delete('/api/v1/file/delete-file/' + fileId)
        .then(({ data }) => data);
}
export function getVchatAppletCode(videoId, videoType) {
    setBaseUrl(1)
    let obj = {
        "videoId":videoId,
        "videoType":videoType
    }
    return axios
        .post('api/v1/vizard-video/gen-wxacode', obj)
        .then(({ data }) => data);
}
//创建视频库
export function getCreatVideoLibrary(videoLibraryName, videoLibraryId) {
    setBaseUrl(1)
    let obj = {
        "videoLibraryName":videoLibraryName,
        "videoLibraryId":videoLibraryId
    }
    return axios
        .post('api/v1/video-library/create' , obj)
        .then(({ data }) => data);
}
//移动视频库
export function getMoveLibrary(videoLibraryId, subVideoLibraryId) {
    setBaseUrl(1)
    let obj = {
        "videoLibraryId":videoLibraryId,
        "subVideoLibraryId":subVideoLibraryId
    }
    return axios
        .post('api/v1/video-library/move' , obj)
        .then(({ data }) => data);
}
//分享视频库
export function shareVideoLibrary(videoLibraryId) {
    setBaseUrl(1)
    return axios
        .post('api/v1/share/share-video-library' , {videoLibraryId})
        .then(({ data }) => data);
}
//视频库列表
export function getLibraryList(videoLibraryName, pageType, pageIndex, pageSize) {
    setBaseUrl(1)
    let obj = {
        "videoLibraryName": videoLibraryName,
        "pageType":pageType,
        "pageIndex": pageIndex,
        "pageSize": pageSize
    }
    return axios
        .post('/api/v1/video-library/list', obj, {
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(({ data }) => data);
}
//添加视频到视频库
export function getAddVideoToLibrary(addVideoLibraryIds, videoId, videoType) {
    setBaseUrl(1)
    let obj = {
        "videoLibraryIds":addVideoLibraryIds,
        "videoId":videoId,
        "videoType":videoType
    }
    return axios
        .post('api/v1/video-library/detail/update', obj)
        .then(({ data }) => data);
}
//批量添加视频到视频库
export function addCheckedDataToLibrary(addVideoLibraryIds, videoId, videoType) {
    setBaseUrl(1)
    let obj = {
        "videoLibraryIds":addVideoLibraryIds,
        "videoId":videoId,
        "videoType":videoType
    }
    return axios
        .post('api/v1/video-library/detail/batch-add', obj)
        .then(({ data }) => data);
}
//我的视频批量删除
export async function deleteCheckedData(id){
    setBaseUrl(1)
    let obj = {
        "id":id,
    }
    return axios
        .post('/api/v1/vizard-video/batch-delete', obj,{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(({ data }) => data);
}
//我的导出批量删除
export async function deleteCheckedFinalVideo(finalVideoId){
    setBaseUrl(1)
    let obj = {
        "finalVideoId":finalVideoId,
    }
    return axios
        .post('/api/v1/final-video/batch-delete', obj)
        .then(({ data }) => data);
}
//分享给我批量删除
export async function deleteCheckedShareWithMe(id){
    setBaseUrl(1)
    let obj = new FormData();
    obj.append("id", id);
    return axios
        .post('/api/v1/share/batch-delete-my-share-view-history', obj,{
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        })
        .then(({ data }) => data);
}
//视频库中的视频列表
export function getAllLibItemList(videoLibraryID, videoName, pageIndex, pageSize) {
    setBaseUrl(1);
    let obj = {
        "videoLibraryId":videoLibraryID,
        "pageIndex":pageIndex,
        "pageSize":pageSize
    }
    if (videoName !== '') {
        obj.videoName = videoName
    }
    return axios
        .post('/api/v1/video-library/detail/list', obj,{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(({ data }) => data);
}
//检查视频在哪些视频库
export function getVideoInWhichLibrary(videoId, videoType) {
    setBaseUrl(1)
    let obj = {
        "videoId":videoId,
        "videoType":videoType,
    }
    return axios
        .post('api/v1/video-library/detail/check-library', obj)
        .then(({ data }) => data);
}
//视频库重命名
export async function vizardLibRename(id, videoLibName) {
    setBaseUrl(1)
    let obj = {
        videoLibraryId:id,
        videoLibraryName:videoLibName
    };
    return axios
        .post('/api/v1/video-library/rename', obj, {
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(({ data }) => data);
}
//删除视频库
export async function deleteVizardVideoLibrary(libraryId){
    setBaseUrl(1)
    return axios
        .delete('/api/v1/video-library/delete/' + libraryId)
        .then(({ data }) => data);
}
//批量删除视频库
export async function deleteCheckedLibrary(videoLibraryId){
    setBaseUrl(1)
    let obj = {
        "videoLibraryId":videoLibraryId,
    }
    return axios
        .post('/api/v1/video-library/batch-delete', obj,{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(({ data }) => data);
}
//获取视频库第一层子目录
export async function getAllSubVideoList(videoLibraryId){
    setBaseUrl(1)
    return axios
        .get('/api/v1/video-library/list-sub-library/' + videoLibraryId)
        .then(({ data }) => data);
}

//将视频库下视频 从视频库中移除
export async function deleteVizardVideoLibraryItem(videolibraryDetailId){
    setBaseUrl(1)
    console.log('videolibraryDetailId', videolibraryDetailId)
    return axios
        .delete('/api/v1/video-library/detail/remove/' + videolibraryDetailId)
        .then(({ data }) => data);
}
//批量移除视频库子级文件夹、视频
export async function deleteCheckedVideoLibraryItem(videoLibraryDetailId){
    setBaseUrl(1)
    let obj = {
        "videoLibraryDetailId":videoLibraryDetailId,
    }
    return axios
        .post('/api/v1/video-library/detail/batch-remove', obj,{
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(({ data }) => data);
}
//查看视频的观看历史列表
export async function getViewerList(videoId, videoType){
    setBaseUrl(1)
    let obj = {
        videoId,
        videoType
    };
    return axios
        .post('/api/v1/vizard-video/list-view-history', obj)
        .then(({ data }) => data);
}
vueSetting();

//用户修改昵称
export async function changeUserName(name) {
    await setBaseUrl(1)
    let obj = { "nickname":name };
    return axios
        .post('/api/v1/user/edit-nickname', obj)
        .then(({ data }) => data);
}
//添加注释
export async function addComment(videoId, videoType, content, beginTime, endTime) {
    await setBaseUrl(1)
    let obj = {videoId, videoType, content, beginTime, endTime};
    return axios
        .post('/api/v1/comment/add', obj)
        .then(({ data }) => data);
}
//注释列表
export async function getCommentList(videoId, videoType) {
    await setBaseUrl(1)
    let obj = {videoId, videoType};
    return axios
        .post('/api/v1/comment/list', obj)
        .then(({ data }) => data);
}
//修改注释
export async function updateComment(id, content, beginTime, endTime) {
    await setBaseUrl(1)
    let obj = {id, content, beginTime, endTime};
    return axios
        .post('/api/v1/comment/update', obj)
        .then(({ data }) => data);
}
//删除注释
export async function deleteComment(id) {
    await setBaseUrl(1)
    return axios
        .delete('/api/v1/comment/delete/' + id)
        .then(({ data }) => data);
}
//分享给我 视频库详情
export async function getShareLibraryDetail(shareId, pageIndex, pageSize, videoName) {
    await setBaseUrl(1)
    let obj = {shareId, pageIndex, pageSize};
    if (videoName !== '') {
        obj.videoName = videoName
    }
    return axios
        .post('/api/v1/share/share-library-detail', obj)
        .then(({ data }) => data);
}
//分享给我中 没有shareCode的项目 获取shareCode
export async function getNewAddShareCode(videoLibraryId, subVideoLibraryId, videoId, videoType, msDuration) {
    await setBaseUrl(1)
    let obj = {videoLibraryId, subVideoLibraryId, videoId, videoType, msDuration};
    return axios
        .post('/api/v1/share/gen-new-share-info', obj)
        .then(({ data }) => data);
}
//触发分享视频转码
export async function triggerTranscode(shareId , msDuration) {
    await setBaseUrl(1)
    let obj = {
        shareId: shareId,
        msDuration: msDuration
    };
    return axios
        .post('/api/v1/share/trigger-share-video', obj)
        .then(({ data }) => data);
}
//获取自定义背景图列表
export async function getBackgroundList() {
    await setBaseUrl(1)
    return axios
        .get('/api/v1/background/list')
        .then(({ data }) => data);
}
//删除背景图
export async function deleteBackground(id) {
    await setBaseUrl(1)
    return axios
        .delete('api/v1/background/delete/' + id)
        .then(({data}) => data)
}
//上传背景图
export async function uoloadBackgroundImage(file) {
    await setBaseUrl(1)
    let obj = new FormData();
    obj.append("file", file);
    return axios
        .post('api/v1/background/upload', obj)
        .then(({data}) => data)
}
//保存模板
export async function saveVideoTemplateJson(videoId, videoType, templateJsonStr) {
    await setBaseUrl(1)
    let obj = {videoId, videoType, templateJsonStr}
    return axios
        .post('api/v1/vizard-video/upload-template-json', obj)
        .then(({data}) => data)
}
// 分享的视频，添加画布参数
export async function saveShareVideoTemplateJson(shareId, templateStr) {
    await setBaseUrl(1)
    let obj = {shareId, templateStr: templateStr};
    return axios
        .post('api/v1/share/save-share-snapshot', obj)
        .then(({data}) => data)
}
// 提交画质增强请求。
export async function submitImageQuality(videoId, cutTime, videoType) {
    await setBaseUrl(1)
    let obj = {videoId, cutTime, videoType};
    return axios
        .post('api/v1/image-enhance/submit', obj)
        .then(({data}) => data)
}
// 查询画质增强结果。
export async function getImageQualityResult(videoId, videoType) {
    await setBaseUrl(1)
    let obj = {videoId, videoType};
    return axios
        .post('api/v1/image-enhance/query', obj)
        .then(({data}) => data)
}
// 记录用户观看过清理提示
export async function sendViewClearPrompt() {
    await setBaseUrl(1)
    return axios
        .post('api/v1/user/view-clear-prompt')
        .then(({data}) => data)
}
// 获取云空间套餐包列表
export async function getCloudSpacePackageList() {
    await setBaseUrl(1)
    return axios
        .get('api/v2/cloud-space/list-cloud-space-package')
        .then(({data}) => data)
}
// 获取用户云空间权益信息
export async function getUserCloudSpaceInfo() {
    await setBaseUrl(1)
    return axios
        .get('api/v1/cloud-space/user-cloud-space-info')
        .then(({data}) => data)
}
// 获取云空间套餐包签约二维码
export async function getCloudSpaceQrCode(cloudSpacePackageId, payType = 2) {
    await setBaseUrl(1)
    return axios
        .post('api/v2/cloud-space/buy', {cloudSpacePackageId, payType})
        .then(({data}) => data)
}

export async function getVizardPayState(orderId) {
    await setBaseUrl(1)
    return axios
        .post('api/v2/cloud-space/pay-state', {orderId})
        .then(({data}) => data)
}

/**
 * 一键删除过期视频。
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function deleteExpiredVideo() {
    await setBaseUrl(1)
    return axios
        .delete('api/v1/vizard-video/delete-expired-video')
        .then(({data}) => data)
}

/**
 * 查看视频解冻状态
 * 针对较久的视频，播放需从归档模式解冻。
 * @param ossFileId
 * @returns {Promise<AxiosResponse<any>>}
 */
export async function queryVideoRestoreStatus(ossFileId) {
    await setBaseUrl(1);
    return axios
        .post('api/v1/oss-file/restore-state/' + ossFileId)
        .then(({data}) => data)
}
