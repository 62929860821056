<template>
    <!-- 演示遮罩方式 -->
    <div class="captcha-container" v-show="captchaMaskDisplay">
        <div id="captcha-div"></div>
    </div>
</template>

<script>
export default {
    name: "VizardCaptcha",
    data() {
        return {};
    },
    computed: {
        captchaMaskDisplay() {
            return this.$store.state.captchaMaskDisplay;
        },
    },
    watch: {
        // 使用 watch 监听 Vuex 状态变化
        "$store.state.captchaMaskDisplay"(newValue) {
            if (newValue) {
                this.openCaptchaMask();
            }
        },
    },
    methods: {
        openCaptchaMask() {
            const captchaConfig = {
                // 请求验证码接口
                requestCaptchaDataUrl:
                    process.env.VUE_APP_API_SERVER + "/api/v1/captcha/gen",
                // 验证验证码接口
                validCaptchaUrl:
                    process.env.VUE_APP_API_SERVER + "/api/v1/captcha/check",
                // 绑定的div
                bindEl: "#captcha-div",
                // 验证成功回调函数
                validSuccess: (res, c, t) => {
                    // 销毁验证码
                    t.destroyWindow();
                    this.$store.commit("setCaptchaKey", res.data);
                    this.$store.commit("setCaptchaMaskDisplay", false);
                },
                // 验证失败的回调函数(可忽略，如果不自定义 validFail 方法时，会使用默认的)
                validFail: (res, c, tac) => {
                    // 验证失败后重新拉取验证码
                    tac.reloadCaptcha();
                },
                btnCloseFun: (el, tac) => {
                    this.$store.commit("setCaptchaMaskDisplay", false);
                    tac.destroyWindow();
                },
            };

            const style = {
                // 滑块按钮样式
                btnUrl: "/static/tac/images/slider.png",
                // 整个人机验证弹窗容器的背景样式
                // bgUrl:
                //     "https://minio.tianai.cloud/public/captcha-btn/btn3-bg.jpg",
                // 左下角logo地址
                // logoUrl:
                //     "https://minio.tianai.cloud/public/static/captcha/images/logo.png",
                // 滑动边框样式
                moveTrackMaskBgColor: "rgba(0, 110, 255, 0.4)",
                moveTrackMaskBorderColor: "#D1D1D1",
            };

            window.initTAC("static/tac", captchaConfig, style).then((tac) => {
                tac.init();
            });
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.captcha-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    .captcha-div {
        width: 100%;
        height: 100%;
    }
}
</style>
